import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/results/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getResultById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/results/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteResult = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/results/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createResult = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/results", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateResult = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/results/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/results", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByProjectId = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/results/by-project/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Someting went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ResultService = {
  search,
  getResultById,
  deleteResult,
  createResult,
  updateResult,
  getAll,
  getByProjectId,
};

export default ResultService;
