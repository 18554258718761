/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { ROLE, USER_STATUS } from "utils/enum";
import { Panel } from "primereact";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    avatar: "",
    first_name: "",
    last_name: "",
    full_name: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    is_leader: false,
    referred_by: "",
    percent_sales: 0,
    is_swap: true,
    is_withdraw: true,
    is_deposit: true,
    is_claim: true,
    id_region: false,
    id_province: false,
    percent_region: 0,
    percent_provice: 0,
    id_inherit: "",
  };

  const emptyProfile = {
    career_id: null,
    location: {},
    home_town: {},
    nation_id: null,
    religion_id: null,
  };

  const [details, setDetails] = useState(emptyData);
  const [profile, setProfile] = useState(emptyProfile);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setProfile(data.profile || emptyProfile);
    }
  }, [data]);

  const onChangeUser = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            profile,
          },
        });
      } else {
        await UserService.createUser({
          body: {
            ...details,
            profile,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Email</label>
            <InputText
              id="email"
              value={details.email}
              onChange={(e) => onChangeUser("email", e.target.value)}
              required
              autoFocus
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Phone</label>
            <InputText
              id="phone"
              value={details.phone}
              onChange={(e) => onChangeUser("phone", e.target.value)}
              required
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="last_name">Full Name</label>
            <InputText
              id="full_name"
              value={details.full_name}
              onChange={(e) => onChangeUser("full_name", e.target.value)}
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="description">Role</label>
            <Dropdown
              value={details.role}
              options={Object.keys(ROLE).map((k) => ({
                label: k,
                value: ROLE[k],
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Select role"
              onChange={(e) => onChangeUser("role", e.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Status</label>
            <Dropdown
              value={details.status}
              options={Object.keys(USER_STATUS).map((k) => ({
                label: k,
                value: USER_STATUS[k],
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChangeUser("status", e.value)}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(Details);
