import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/categories/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCategoryById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/categories/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteCategory = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/categories/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createCategory = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/categories", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateCategory = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/categories/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/categories")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ordering = (req: { body: { id: string; order: number }[] }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/categories/ordering/sort", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CategoryService = {
  search,
  getCategoryById,
  deleteCategory,
  createCategory,
  updateCategory,
  getAll,
  ordering,
};

export default CategoryService;
