import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/prompts/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPromptById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/prompts/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deletePrompt = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/prompts/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPrompt = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/prompts", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updatePrompt = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/prompts/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const clonePrompt = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/prompts/clone/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPromptByProject = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/prompts/project/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PromptService = {
  search,
  getPromptById,
  deletePrompt,
  createPrompt,
  updatePrompt,
  getPromptByProject,
  clonePrompt,
};

export default PromptService;
