import { Dropdown } from "primereact/dropdown";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ResultService from "services/results";
import { COMMON_STATUS } from "utils/enum";
import { showToast } from "utils/common";
import { Slider } from "primereact/slider";
import { InputTextarea } from "primereact/inputtextarea";
import ActionStatus from "components/action-status";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, projects } = props;

  const emptyData = {
    project_id: "",
    text: "",
    rating: 0,
    status: "",
    feedback: "",
    copy: "no",
    more: "no",
    removed: "no",
    bookmark: "no",
  };

  const [result, setResult] = useState(emptyData);
  const [isHidden, setIsHidden] = useState(true);
  const [isShowMore, setIsShowMore] = useState(false);
  const promptTextRef = useRef(null);
  const parentPromptTextRef = useRef(null);

  useLayoutEffect(() => {
    if (promptTextRef.current && parentPromptTextRef.current) {
      if (
        promptTextRef.current.offsetWidth ===
        parentPromptTextRef.current.offsetWidth
      ) {
        setIsShowMore(true);
      }
    }
  }, [
    promptTextRef.current?.offsetWidth,
    parentPromptTextRef.current?.offsetWidth,
  ]);

  useEffect(() => {
    if (data) {
      setResult(data);
    }
  }, [data]);

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await ResultService.updateResult({
          params: {
            id: data._id,
          },
          body: {
            ...result,
            rating: Number(result.rating),
          },
        });
      } else {
        await ResultService.createResult({
          body: {
            ...result,
            rating: Number(result.rating),
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Prompt saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChangePrompt = (name, value) => {
    setResult((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const currentPrompt = () => {
    let prompt = data.promptText || "";
    const variables = data?.variables;
    if (data?.tone) {
      variables["tone"] = data.tone;
    }
    for (const v in variables) {
      prompt = prompt.replaceAll(
        `{{${v}}}`,
        `<span class="text-primary">${variables[v]}</span>`
      );
    }

    return (
      <div ref={parentPromptTextRef}>
        <p
          ref={promptTextRef}
          className="text-lg font-bold"
          dangerouslySetInnerHTML={{ __html: prompt }}
          style={
            isHidden && isShowMore
              ? {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }
              : {
                  width: "fit-content",
                }
          }
        ></p>
        {isShowMore && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={() => setIsHidden((state) => !state)}>
              {isHidden ? "Show more" : "Hidden"}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="grid">
        <div className="field col-12 md:col-4">
          <label htmlFor="projects">Project</label>
          <Dropdown
            value={result.project_id}
            options={projects.map((project) => ({
              label: project.name,
              value: project._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select project"
            onChange={(e) => onChangePrompt("project_id", e.value)}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label htmlFor="status">Status</label>
          <Dropdown
            value={result.status}
            options={Object.keys(COMMON_STATUS).map((status) => ({
              label: status,
              value: COMMON_STATUS[status],
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select status"
            onChange={(e) => onChangePrompt("status", e.value)}
          />
        </div>
        <div className="field col-12 md:col-4">
          <div className="flex align-items-center justify-content-between">
            <label htmlFor="rating">Rating</label>
            <span>{Number(result.rating)}</span>
          </div>
          <Slider
            max={5}
            className="mt-2 md:mt-4"
            value={result.rating}
            onChange={(e) => onChangePrompt("rating", e.value)}
          />
        </div>
      </div>
      <div className="field md:mt-2">
        <label>Prompt</label>
        {currentPrompt()}
      </div>
      <div className="field">
        <label htmlFor="text">Text</label>
        <InputTextarea
          autoResize
          value={result.text}
          onChange={(e) => onChangePrompt("text", e.target.value)}
        />
      </div>
      <div className="field">
        <label htmlFor="feedback">Feedback</label>
        <InputTextarea
          autoResize
          value={result.feedback}
          onChange={(e) => onChangePrompt("feedback", e.target.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label>Action</label>
        <div className="flex justify-content-between">
          <ActionStatus name="Copy" status={data?.copy === "yes"} />
          <ActionStatus name="More like this" status={data?.more === "yes"} />
          <ActionStatus name="Bookmark" status={data?.bookmark === "yes"} />
          <ActionStatus name="Delete" status={data?.removed === "yes"} />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
