import { forwardRef, useImperativeHandle, useState } from "react";
import {
  SortableItem,
  SortableItemProps,
  SortableList,
} from "@thaddeusjiang/react-sortable-list";
import CategoryService from "services/categories";
import { showToast } from "utils/common";

const DragHandler = (props) => {
  return (
    <div
      {...props}
      className="flex justify-center items-center rounded border m-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500 hover:text-white duration-300"
    >
      <div className="flex justify-center" title="drag handler">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
          />
        </svg>
      </div>
    </div>
  );
};

const Sort = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [items, setItems] = useState<SortableItemProps[]>(
    data
      .map((item: any) => ({ ...item, id: item._id }))
      .sort((a, b) => a.order - b.order)
  );

  const submit = async () => {
    const newSort = items.map((item, index) => ({
      id: item.id,
      order: index,
    }));
    try {
      setLoading(true);
      await CategoryService.ordering({ body: newSort });
      setLoading(false);
      showToast(toast, "success", "Sort saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  return (
    <div className="grid">
      <div className="field w-full">
        <SortableList items={items} setItems={setItems}>
          {({ items }: { items: SortableItemProps[] }) => (
            <div className="space-y-4">
              {items.map((item: SortableItemProps) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  DragHandler={DragHandler}
                  className="flex border items-center card"
                >
                  <p className="mr-5 text-center border-1 rounded px-2 py-1 flex-1">
                    {item.name}
                  </p>
                  <p className="mr-5 text-center border-1 rounded px-2 py-1 flex-1">
                    {item.description}
                  </p>
                  <p className="mr-5 text-center border-1 rounded px-2 py-1 flex-1">
                    {item.status}
                  </p>
                </SortableItem>
              ))}
            </div>
          )}
        </SortableList>
      </div>
    </div>
  );
};

export default forwardRef(Sort);
