interface IProps {
  name: string;
  status: boolean;
}

const View = ({ name, status }: IProps) => {
  return (
    <div
      style={{ borderRadius: "5px", border: "2px solid #999" }}
      className={`${
        status ? "bg-green-300 border-green-500" : "bg-white"
      } text-800 py-2 px-3 w-max`}
    >
      {name}
    </div>
  );
};

export default View;
