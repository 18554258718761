/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import CategoryService from "services/categories";
import {
  convertObjectToListValue,
  formatLocalTime,
  showToast,
} from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { MultiSelect } from "primereact";
import { COMMON_STATUS } from "utils/enum";
import Sort from "./components/Sort";

const View = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: convertObjectToListValue(COMMON_STATUS),
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogSort = useRef(null);

  const refDetail = useRef(null);
  const refSort = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await CategoryService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const openSort = () => {
    refDialogSort.current.show();
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editCategory = async (data) => {
    const res: any = await CategoryService.getCategoryById({
      params: {
        id: data._id,
      },
    });

    if (res) {
      setDetail({
        ...data,
        ...res.user,
      });
      refDialogDetail.current.show();
    }
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const onSubmitDialogSort = () => {
    refSort.current.submit();
  };

  const onCancelDialogSort = () => {
    refDialogSort.current.close();
  };

  const setLoadingSaveSort = (flg) => {
    refDialogSort.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (project) => {
    setDetail(project);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await CategoryService.deleteCategory({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Category Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Sort"
            icon="bx bx-sort-alt-2"
            className="p-button-warn mr-2"
            onClick={openSort}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 hidden"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help hidden"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editCategory(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:justify-content-between">
      <h5 className="m-0">Manage Categories</h5>
      <div className="grid my-2">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <Calendar
          className="m-1 hidden"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1 hidden"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <MultiSelect
          value={globalFilter.status}
          options={Object.keys(COMMON_STATUS).map((k) => ({
            label: k,
            value: COMMON_STATUS[k],
          }))}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="label"
          placeholder="Status"
          maxSelectedLabels={3}
          className={"w-15rem m-1"}
          showClear
        />
      </div>
    </div>
  );

  const threedot = (sequence: string, limit: number) => {
    if (sequence.length >= limit) {
      return sequence.slice(0, limit) + "...";
    }
    return sequence;
  };

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="STT"
              header="STT"
              style={{ flexGrow: 1, flexBasis: "70px" }}
              body={(_, { rowIndex }) => rowIndex + 1}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="name"
              header="Name"
              body={(rowData) => threedot(rowData.name, 50)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "250px" }}
              field="description"
              header="Description"
              body={(rowData) => threedot(rowData.description, 100)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              frozen
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Category Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
          <VDialog
            ref={refDialogSort}
            header="Categories Sort"
            onSubmit={onSubmitDialogSort}
          >
            <Sort
              ref={refSort}
              data={data}
              onCancel={onCancelDialogSort}
              setLoading={setLoadingSaveSort}
              toast={toast}
              reload={loadData}
            />
          </VDialog>
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
