import { useState, createContext } from "react";
import SettingService from "services/settings";
import UserService from "services/users";

type MasterContext = {
  auth?: any;
  users?: any;
  getUsers?: any;
  setAuth?: any;
  languages?: any;
  getLanguages?: any;
  tones?: any;
  getTones?: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [users, setUsers] = useState([]);
  const [auth, setAuth] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [tones, setTones] = useState([]);

  const getTones = () => {
    SettingService.getSettingsByName({
      params: {
        name: "tones",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setTones(res.setting.value);
      }
    });
  };

  const getLanguages = () => {
    SettingService.getSettingsByName({
      params: {
        name: "languages",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setLanguages(res.setting.value);
      }
    });
  };

  const getUsers = () => {
    UserService.getUsers({
      query: {
        blocked: false,
      },
    }).then((res: any) => {
      if (res && res.users) setUsers(res.users);
    });
  };

  return (
    <MasterContext.Provider
      value={{
        auth,
        users,
        languages,
        tones,
        getTones,
        getLanguages,
        getUsers,
        setAuth,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
