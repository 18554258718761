export enum RequestParams {
  USERID = "userId",
}

export enum MAIL_TEMPLATE {
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SEND_CODE = "SEND_CODE",
  VERIFY_LOGIN = "VERIFY_LOGIN",
}

export enum ROLE {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum LOCATION_TYPES {
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
}

export enum USER_STATUS {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  BLOCKED = "blocked",
}

export enum USER_GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export enum CAREER_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_TYPE {
  MENU = "menu",
  SUBMENU = "submenu",
  TAB = "tab",
}

export enum MENU_TYPE_VIEW {
  LINK = "link",
  INTERNAL = "internal",
  WEBVIEW = "webview",
}

export enum MENU_ROLE {
  ADMIN = "admin",
  USER = "user",
}

export enum COMMON_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum VARIABLE_TYPE {
  SHORT = "short",
  PARAGRAPH = "paragraph",
  LONGPARAGRAPH = "longparagraph",
  DROPDOWN = "dropdown",
}

export enum MENU_POSITION {
  HEADER = "header",
  FOOTER = 'footer'
}
