/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../../layouts/AppTopbar";
import { AppFooter } from "../../layouts/AppFooter";
import { AppMenu } from "../../layouts/AppMenu";
import { AppConfig } from "../../layouts/AppConfig";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import Dashboard from "./dashboards";
import Empty from "./empty";
import Users from "./users";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";

import SettingLanguage from "./settings/languages";
import Projects from "./projects";
import Prompts from "./prompts";
import Results from "./results";
import Categories from "./categories";
// import ProjectChat from "./project-chat";
// import PromptChat from "./prompt-chat";
import Tones from "./settings/tones";
// import Menus from "./landing-page/menus";
// import QuestionAnswer from "./landing-page/question-answer";
// import Blog from "./landing-page/blog";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef: any = useRef();
  const location = useLocation();
  const { auth, getUsers, getLanguages, getTones } = useContext(MasterContext);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (auth) {
      getUsers();
      getLanguages();
      getTones();
    }
  }, [auth]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: { preventDefault: () => void }) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: { preventDefault: () => void }) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: {
    preventDefault: () => void;
  }) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
  };

  const onMenuItemClick = (event: { item: { items: any } }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menus = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/",
          module: "overview",
        },
      ],
    },

    {
      label: "MANAGERMENTS",
      items: [
        {
          label: "Users",
          icon: "bx bxs-user",
          to: "/users",
          badge: "10",
          module: "users",
        },
        {
          label: "Categories",
          icon: "bx bx-category",
          to: "/categories",
          module: "categories",
        },
        {
          label: "Projects",
          icon: "bx bxs-book-content",
          to: "/projects",
          badge: "10",
          module: "projects",
        },
        {
          label: "Prompts",
          icon: "bx bx-expand-horizontal",
          to: "/prompts",
          module: "prompts",
        },
        {
          label: "Results",
          icon: "bx bxs-memory-card",
          to: "/results",
          module: "results",
        },
      ],
    },
    // {
    //   label: "CHAT",
    //   items: [
    //     {
    //       label: "Projects",
    //       icon: "bx bx-food-menu",
    //       to: "/chat/projects",
    //       module: "chat",
    //     },
    //     {
    //       label: "Prompts",
    //       icon: "bx bxs-terminal",
    //       to: "/chat/prompts",
    //       module: "chat",
    //     },
    //   ],
    // },
    {
      label: "SETTINGS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Languages",
          icon: "bx bx-list-ul",
          to: "/settings/languages",
          module: "settings",
        },
        {
          label: "Tones",
          icon: "bx bxs-tone",
          to: "/settings/tones",
          module: "settings",
        },
      ],
    },
    {
      label: "LANDING PAGE",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Menus",
          icon: "bx bx-list-ul",
          to: "/landing-page/menus",
          module: "menus",
        },
        {
          label: "Question & Answer",
          icon: "bx bx-list-ul",
          to: "/landing-page/question-answer",
          module: "question-answer",
        },
        {
          label: "Blog",
          icon: "bx bx-list-ul",
          to: "/landing-page/blog",
          module: "blog",
        },
      ]
    }
  ];

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menus}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Dashboard colorMode={layoutColorMode} location={location} />
              )}
            />
            <Route path="/users" component={Users} />
            <Route path="/categories" component={Categories} />
            <Route path="/projects" component={Projects} />
            <Route path="/prompts" component={Prompts} />
            <Route path="/results" component={Results} />
            <Route path="/settings/languages" component={SettingLanguage} />
            <Route path="/settings/tones" component={Tones} />
            {/* <Route path="/chat/projects" component={ProjectChat} />
            <Route path="/chat/prompts" component={PromptChat} /> */}
            <Route path="*" component={Empty} />
          </Switch>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default App;
