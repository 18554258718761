/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS } from "utils/enum";
import CategoryService from "services/categories";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    key: "",
    name: "",
    description: "",
    status: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChangeCategory = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CategoryService.updateCategory({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await CategoryService.createCategory({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Category saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="key">Key</label>
          <InputText
            id="key"
            value={details.key}
            onChange={(e) => onChangeCategory("key", e.target.value)}
            required
            autoFocus
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            value={details.name}
            onChange={(e) => onChangeCategory("name", e.target.value)}
            required
            autoFocus
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Description</label>
          <InputText
            id="description"
            value={details.description}
            onChange={(e) => onChangeCategory("description", e.target.value)}
            required
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Status</label>
          <Dropdown
            value={details.status}
            options={Object.keys(COMMON_STATUS).map((k) => ({
              label: k,
              value: COMMON_STATUS[k],
            }))}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => onChangeCategory("status", e.value)}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(Details);
