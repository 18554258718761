export const getUrlImage = (img) => {
  return `${
    process.env.REACT_APP_IMG || "https://s3.vdiarybook.net/api/files"
  }/${img}`;
};

export function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

export const formatNumber = (number) => {
  return new Intl.NumberFormat([], { minimumFractionDigits: 2 }).format(number);
};

export const formatCurrencyByType = (
  number,
  type = "en-US",
  currency = "USD",
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(type || [], {
    minimumFractionDigits,
    style: "currency",
    currency,
  }).format(number);
};

export const formatLocalTime = (data) => {
  return new Date(data).toLocaleString();
};

export const showToast = (toast, type = "success", messages = "") => {
  let summary = "Successful";

  switch (type) {
    case "success":
      summary = "Success Message";
      break;
    case "info":
      summary = "Info Message";
      break;
    case "warn":
      summary = "Warn Message";
      break;
    case "error":
      summary = "Error Message";
      break;
    default:
      break;
  }

  toast.current.show({
    severity: type,
    summary: summary,
    detail: messages,
    life: 2000,
  });
};

export const checkIncludeString = (contain, review) => {
  return contain.toLowerCase().includes(review.toLowerCase());
};

export const compareDate = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime();
};

export const getUrlHash = (hash) => {
  return `${
    process.env.REACT_APP_VZX_SCAN || "https://block.Vdiarybook.com"
  }/tx/${hash}`;
};

export const getFromTo = (data) => {
  return `${(data || "").slice(0, 5)}...${data.slice(
    data.length - 3,
    data.length
  )}`;
};

export const convertObjectToListValue = (object: {}) => {
  return Object.keys(object).map((k) => object[k]);
};

export const getCategoryName = (group_category: any, group_key : string) => {
  if (group_category && group_category.length > 0){
    let group = group_category.find(e => e.group === group_key);
    return group ? group.name : '';
  }
};

export const selectEmpty = { id : '-1' , label : 'Select', key : "-1"}

