import { MasterContext } from "contexts/MasterContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { nanoid } from "nanoid";
import { InputText } from "primereact/inputtext";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { Toast } from "primereact/toast";

const View = () => {
  const { languages } = useContext(MasterContext);

  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "tones",
      },
    });
    if (res && res.setting) {
      setState(res.setting.value);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAdd = (language) => {
    setState((state) => [...state, { language, id: nanoid(8), name: "" }]);
  };

  const handleSub = (id) => {
    setState(state.filter((tone) => tone.id !== id));
  };

  const handleChange = (id, key, value) => {
    setState((state) => {
      return state.map((tone) =>
        tone.id === id ? { ...tone, [key]: value } : tone
      );
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await SettingService.updateSetting({
        body: {
          name: "tones",
          value: state,
        },
      });
      setLoading(false);
      getData();
      showToast(toast, "success", "Update success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Tones</h4>
          </div>
          <div>
            {languages.map((language) => {
              return (
                <Panel
                  key={language.id}
                  header={language.name}
                  toggleable
                  className="mb-4"
                  collapsed={true}
                >
                  <ul className="p-0">
                    {state
                      .filter((tone) => tone.language === language.key)
                      .map((tone) => (
                        <li className="grid">
                          <div className="field col-12 md:col-6">
                            <label htmlFor="">Name</label>
                            <InputText
                              className="w-full"
                              value={tone.name}
                              onChange={(e) =>
                                handleChange(tone.id, "name", e.target.value)
                              }
                            />
                          </div>
                          <div className="flex col-12 md:col-1 align-items-center">
                            <Button
                              icon="pi pi-times"
                              className="p-button-rounded p-button-danger"
                              aria-label="Cancel"
                              onClick={() => handleSub(tone.id)}
                            />
                          </div>
                        </li>
                      ))}
                  </ul>
                  <div>
                    <Button
                      onClick={() => handleAdd(language.key)}
                      icon="bx bxs-add-to-queue"
                      className="p-button-rounded p-button-success mb-3"
                      aria-label="Search"
                    />
                  </div>
                </Panel>
              );
            })}
          </div>
          <div>
            <Button label="Submit" loading={loading} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
