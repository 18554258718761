/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import {
  convertObjectToListValue,
  formatLocalTime,
  showToast,
} from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { MultiSelect } from "primereact";
import { COMMON_STATUS } from "utils/enum";
import PromptService from "services/prompts";
import ProjectService from "services/projects";

const View = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: convertObjectToListValue(COMMON_STATUS),
    projectIds: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [projects, setProjects] = useState([]);
  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);

  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    try {
      const res: any = await ProjectService.getAll({});
      if (res) {
        setProjects(res);
      }
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      const res: any = await PromptService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editPrompt = async (data) => {
    setDetail({
      ...data,
    });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (row) => {
    setDetail(row);
    refDialogDelete.current.show();
  };

  const clone = async (row) => {
    try {
      await PromptService.clonePrompt({ params: { id: row._id } });
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      await PromptService.deletePrompt({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Prompt Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 hidden"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help hidden"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editPrompt(rowData)}
        />
        <Button
          icon="bx bx-copy-alt"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => clone(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:justify-content-between">
      <h5 className="m-0">Manage Prompts</h5>
      <div className="grid my-2 align-items-center">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <MultiSelect
          filter
          value={globalFilter.projectIds}
          options={projects.map((project) => ({
            label: project.name,
            value: project._id,
          }))}
          onChange={(e: any) => onSearch("projectIds", e.value)}
          optionLabel="label"
          placeholder="Projects"
          maxSelectedLabels={3}
          className={"w-15rem m-1"}
          showClear
        />
        <Calendar
          className="m-1 hidden"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1 hidden"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <MultiSelect
          value={globalFilter.status}
          options={Object.keys(COMMON_STATUS).map((k) => ({
            label: k,
            value: COMMON_STATUS[k],
          }))}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="label"
          placeholder="Status"
          maxSelectedLabels={3}
          className={"w-15rem m-1"}
          showClear
        />
      </div>
    </div>
  );

  const threedot = (sequence: string, limit: number) => {
    if (sequence?.length >= limit) {
      return sequence.slice(0, limit) + "...";
    }
    return sequence;
  };

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="STT"
              header="STT"
              style={{ flexGrow: 1, flexBasis: "70px" }}
              body={(_, { rowIndex }) => rowIndex + 1}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "100px" }}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "250px" }}
              field="project"
              header="Project"
              sortable
              body={(rowData) => threedot(rowData.project?.name, 20)}
            ></Column>
            <Column
              field="model_type"
              header="Model type"
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
            <Column
              field="model"
              header="Model"
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="temperature"
              header="Temperature"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="top_p"
              header="Top P"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="max_tokens"
              header="Max Tokens"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="n"
              header="N"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="best_of"
              header="Best Of"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="frequency_penalty"
              header="Frequency Penalty"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="presence_penalty"
              header="Presence Penalty"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              header="Average Rating"
              body={(rowData) => {
                if (rowData?.average_rating) {
                  const rating = Object.values<number>(
                    rowData.average_rating
                  ).reduce((prev, next) => prev + next, 0);
                  const numberOfRating = Object.keys(
                    rowData.average_rating
                  ).length;
                  return (rating / numberOfRating).toFixed(2);
                } else {
                  return "None";
                }
              }}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="count.feedback"
              header="Total Feedback"
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              header="Positive Actions"
              body={(rowData) => {
                const totalCopy = rowData.count.copy;
                const totalBookmark = rowData.count.bookmark;
                const totalDelete = rowData.count.removed;
                const totalPositiveAction = totalCopy + totalBookmark;
                const totalAction = totalPositiveAction + totalDelete;

                if (totalAction) {
                  return `${((totalPositiveAction / totalAction) * 100).toFixed(
                    1
                  )}%`;
                } else {
                  return "None";
                }
              }}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              frozen
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "160px" }}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Prompt Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              projects={projects}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
