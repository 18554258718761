/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS, VARIABLE_TYPE } from "utils/enum";
import ProjectService from "services/projects";
import { Button } from "primereact/button";
import { nanoid } from "nanoid";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Tree } from "primereact/tree";
import { Panel } from "primereact/panel";
import ReactQuillEditor from "react-quill";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categories,
    languages,
    tones,
  } = props;

  const emptyData = {
    name: "",
    description: "",
    image: "",
    variables: [],
    status: "enable",
    category_id: "",
    languages: [{ key: "en", name: "English" }],
    tones: {},
    output_languages: [],
    content: "",
    number_of_prompts_used: 1,
    is_vip: false,
  };

  const [details, setDetails] = useState(emptyData);
  const [selectedTones, setSelectedTones] = useState(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      const formatObjectVariable = data.variables.map((variable) => ({
        id: nanoid(8),
        ...variable,
      }));
      const formatSelectedTones = Object.keys(data?.tones || {}).reduce(
        (prev, next) => {
          return {
            ...prev,
            [next]: {
              checked:
                data.tones[next].length ===
                tones.filter((tone) => tone.language === next).length,
              partialChecked:
                data.tones[next].length !==
                tones.filter((tone) => tone.language === next).length,
            },
            ...data.tones[next].reduce((prevChild, nextChild) => {
              return {
                ...prevChild,
                [nextChild.name]: {
                  checked: true,
                },
              };
            }, {}),
          };
        },
        {}
      );
      setSelectedTones(formatSelectedTones);
      setDetails({ ...data, variables: formatObjectVariable });
    }
  }, [data]);

  const onChangeProject = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await ProjectService.updateProject({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await ProjectService.createProject({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Project saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const handleAdd = () => {
    setDetails((state) => ({
      ...state,
      variables: [
        ...state.variables,
        {
          id: nanoid(8),
          type: "short",
          name: "",
          description: "",
          defaultValue: "",
          placeholder: "",
          options: [],
          limit: 600,
        },
      ],
    }));
  };

  const handleAddOption = (id) => {
    setDetails((state) => ({
      ...state,
      variables: state.variables.map((variable) =>
        variable.id === id
          ? {
              ...variable,
              options: [
                ...variable.options,
                { id: nanoid(8), name: "", value: "" },
              ],
            }
          : variable
      ),
    }));
  };

  const handleChangeOption = (varId, opId, key, value) => {
    setDetails((state) => ({
      ...state,
      variables: state.variables.map((variable) =>
        variable.id === varId
          ? {
              ...variable,
              options: variable.options.map((item) =>
                item.id === opId ? { ...item, [key]: value } : item
              ),
            }
          : variable
      ),
    }));
  };

  const handleSubOption = (varId, opId) => {
    setDetails((state) => ({
      ...state,
      variables: state.variables.map((variable) =>
        variable.id === varId
          ? {
              ...variable,
              options: variable.options.filter((item) => item.id !== opId),
            }
          : variable
      ),
    }));
  };

  const handleChange = (index, name, value) => {
    let limit = 0;
    if (name === "type") {
      if (value === VARIABLE_TYPE.SHORT) limit = 600;
      if (value === VARIABLE_TYPE.PARAGRAPH) limit = 600;
      if (value === VARIABLE_TYPE.LONGPARAGRAPH) limit = 15000;
    }
    setDetails((state) => ({
      ...state,
      variables: state.variables.map((item) =>
        item.id === index
          ? {
              ...item,
              [name]: value,
              ...(limit && { limit }),
            }
          : item
      ),
    }));
  };
  const handleSub = (id) => {
    setDetails((state) => ({
      ...state,
      variables: state.variables.filter((s) => s.id !== id),
    }));
  };

  const renderTones = () => {
    return details.languages?.map(
      (language: { id: string; key: string; name: string }) => ({
        key: `${language.key}`,
        label: language.name,
        children: tones
          .filter((tone) => tone.language === language.key)
          .map((tone) => ({
            key: `${tone.name}`,
            label: tone.name,
          })),
      })
    );
  };

  const handleSetTones = (value) => {
    const result = details.languages.reduce((prev, next) => {
      return {
        ...prev,
        [next.key]: tones.filter(
          (tone) =>
            tone.language === next.key &&
            value.hasOwnProperty(tone.name) &&
            value[tone.name].checked
        ),
      };
    }, {});
    setDetails((state) => ({
      ...state,
      tones: result,
    }));
  };
  console.log(details);
  return (
    <>
      <Panel header="Options" toggleable>
        <div className="grid">
          <div className="field col-12">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.name}
              onChange={(e) => onChangeProject("name", e.target.value)}
              required
              autoFocus
            />
          </div>
          <div className="field col-12">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              autoResize
              value={details.description}
              onChange={(e) => onChangeProject("description", e.target.value)}
              required
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="description">Categories</label>
            <Dropdown
              filter
              value={details.category_id}
              options={categories.map((category) => ({
                label: category.name,
                value: category._id,
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Select category"
              onChange={(e) => onChangeProject("category_id", e.value)}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Status</label>
            <Dropdown
              value={details.status}
              options={Object.keys(COMMON_STATUS).map((k) => ({
                label: k,
                value: COMMON_STATUS[k],
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChangeProject("status", e.value)}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="number_of_prompts_used">
              Number Of Prompts Used
            </label>
            <InputNumber
              min={0}
              value={details.number_of_prompts_used}
              onChange={(e) =>
                onChangeProject("number_of_prompts_used", e.value)
              }
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="languages">Input Languages</label>
            <MultiSelect
              value={details.languages.map((language) => language.key)}
              options={languages.map((k) => ({
                label: k.name,
                value: k.key,
              }))}
              onChange={(e: any) =>
                onChangeProject(
                  "languages",
                  languages.filter((language) => e.value.includes(language.key))
                )
              }
              optionLabel="label"
              placeholder="Languages"
              showClear
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="output-languages">Output Languages</label>
            <MultiSelect
              value={details.output_languages.map((language) => language.key)}
              options={languages.map((k) => ({
                label: k.name,
                value: k.key,
              }))}
              onChange={(e: any) =>
                onChangeProject(
                  "output_languages",
                  languages.filter((language) => e.value.includes(language.key))
                )
              }
              optionLabel="label"
              placeholder="Output Languages"
              showClear
            />
          </div>
          <div className="field col-12">
            <Checkbox
              inputId="is_vip"
              name="is_vip"
              checked={details.is_vip}
              onChange={(e) => onChangeProject("is_vip", e.checked)}
            />
            <label htmlFor="is_vip" className="mb-0 ml-2">
              VIP
            </label>
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="tones">Tones</label>
            <Tree
              value={renderTones()}
              selectionKeys={selectedTones}
              selectionMode="checkbox"
              onSelectionChange={(e) => {
                setSelectedTones(e.value);
                handleSetTones(e.value);
              }}
            />
          </div>
        </div>
      </Panel>
      <Panel header="Variables" toggleable className="mt-5" collapsed={true}>
        <div className="field col-12">
          <div className="grid">
            {details.variables.map((variable) => {
              return (
                <div
                  className="col-12 grid border-bottom-1 mb-5 card"
                  key={variable.id}
                >
                  <div className="field col-12 md:col-4">
                    <label htmlFor="name">Name</label>
                    <InputText
                      id="name"
                      value={variable.name}
                      onChange={(e) =>
                        handleChange(variable.id, "name", e.target.value)
                      }
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <label htmlFor="description">Description</label>
                    <InputText
                      id="description"
                      value={variable.description}
                      onChange={(e) =>
                        handleChange(variable.id, "description", e.target.value)
                      }
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <label htmlFor="type">Type</label>
                    <Dropdown
                      value={variable.type}
                      options={Object.keys(VARIABLE_TYPE).map((k) => ({
                        label: k,
                        value: VARIABLE_TYPE[k],
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) =>
                        handleChange(variable.id, "type", e.value)
                      }
                    />
                  </div>

                  <div className="field col-12 md:col-6">
                    <label htmlFor="default_value">Default Value</label>
                    {(variable.type === "short" ||
                      variable.type === "dropdown") && (
                      <InputText
                        value={variable.defaultValue}
                        onChange={(e) =>
                          handleChange(
                            variable.id,
                            "defaultValue",
                            e.target.value
                          )
                        }
                      />
                    )}
                    {(variable.type === "paragraph" ||
                      variable.type === "longparagraph") && (
                      <InputTextarea
                        value={variable.defaultValue}
                        onChange={(e) =>
                          handleChange(
                            variable.id,
                            "defaultValue",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="default_value">Placeholder</label>
                    {(variable.type === "short" ||
                      variable.type === "dropdown") && (
                      <InputText
                        value={variable.placeholder}
                        onChange={(e) =>
                          handleChange(
                            variable.id,
                            "placeholder",
                            e.target.value
                          )
                        }
                      />
                    )}
                    {(variable.type === "paragraph" ||
                      variable.type === "longparagraph") && (
                      <InputTextarea
                        value={variable.placeholder}
                        onChange={(e) =>
                          handleChange(
                            variable.id,
                            "placeholder",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>

                  {variable.type === "dropdown" && (
                    <div className="field col-12">
                      <Panel header="Options" toggleable>
                        <div className="grid">
                          {variable.options?.map((option) => (
                            <div className="grid field w-full">
                              <div className="field col-12 md:col-5">
                                <label htmlFor="option-label">Label</label>
                                <InputText
                                  value={option.name}
                                  onChange={(e) =>
                                    handleChangeOption(
                                      variable.id,
                                      option.id,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="field col-12 md:col-5">
                                <label htmlFor="option-value">Value</label>
                                <InputText
                                  value={option.value}
                                  onChange={(e) =>
                                    handleChangeOption(
                                      variable.id,
                                      option.id,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="flex align-items-center">
                                <Button
                                  icon="pi pi-times"
                                  className="p-button-rounded p-button-danger"
                                  aria-label="Cancel"
                                  onClick={(_) =>
                                    handleSubOption(variable.id, option.id)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="w-full">
                          <Button
                            onClick={() => handleAddOption(variable.id)}
                            icon="bx bxs-add-to-queue"
                            className="p-button-rounded p-button-success mb-3"
                            aria-label="Search"
                          />
                        </div>
                      </Panel>
                    </div>
                  )}
                  <div className="flex align-items-center">
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger"
                      aria-label="Cancel"
                      onClick={(_) => handleSub(variable.id)}
                    />
                  </div>
                </div>
              );
            })}
            <div className="w-full">
              <Button
                onClick={handleAdd}
                icon="bx bxs-add-to-queue"
                className="p-button-rounded p-button-success mb-3"
                aria-label="Search"
              />
            </div>
          </div>
        </div>
      </Panel>
      <Panel
        header="Content Editor"
        toggleable
        className="mt-5"
        collapsed={true}
      >
        <ReactQuillEditor
          value={details.content}
          onChange={(e) => onChangeProject("content", e)}
          style={{ height: "320px", marginBottom: "40px" }}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline"],
              [{ color: [] }, { background: [] }, { size: [] }],
              [{ list: "order" }, { list: "bullet" }],
              ["direction", { align: [] }],
              ["link", "image", "video", "formula"],
              ["clean"],
            ],
          }}
        />
      </Panel>
    </>
  );
};

export default forwardRef(Details);
