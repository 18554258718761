/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from "primereact/dropdown";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import PromptService from "services/prompts";
import { Slider } from "primereact/slider";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { COMMON_STATUS } from "utils/enum";
import { showToast } from "utils/common";

const models = [
  {
    id: "gpt-3.5-turbo-16k",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "chat",
    status: "enable",
  },
  {
    id: "gpt-4",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "chat",
    status: "enable",
  },
  {
    id: "gpt-4-0314",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "chat",
    status: "disable",
  },
  {
    id: "gpt-4-32k",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "chat",
    status: "disable",
  },
  {
    id: "gpt-4-32k-0314",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "chat",
    status: "disable",
  },
  {
    id: "gpt-3.5-turbo",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "chat",
    status: "enable",
  },
  {
    id: "text-davinci-003",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "completion",
    status: "enable",
  },
  {
    id: "text-davinci-002",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "completion",
    status: "disable",
  },
  {
    id: "text-curie-001",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "completion",
    status: "disable",
  },
  {
    id: "text-babbage-001",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "completion",
    status: "disable",
  },
  {
    id: "text-ada-001",
    object: "model",
    owned_by: "openai",
    permission: [],
    type: "completion",
    status: "disable",
  },
];

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, projects } = props;

  const emptyData = {
    project_id: "",
    name: "",
    model_type: "chat",
    model: "",
    prompts: {},
    temperature: 1,
    top_p: 1,
    max_tokens: 400,
    best_of: 1,
    stop: "",
    n: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    status: "enable",
  };

  const [prompt, setPrompt] = useState(emptyData);

  const [targetProject, setTargetProject] = useState(null);

  useEffect(() => {
    if (data) {
      setPrompt(data);
    }
  }, [data]);

  useEffect(() => {
    getTargetProject();
  }, [prompt.project_id]);

  const getTargetProject = useCallback(async () => {
    try {
      if (prompt.project_id && projects) {
        const res: any = projects.find((p) => p._id === prompt.project_id);
        setTargetProject(res);
      }
    } catch (error) {}
  }, [prompt.project_id, projects]);

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await PromptService.updatePrompt({
          params: {
            id: data._id,
          },
          body: {
            ...prompt,
            model_type: prompt.model_type,
          },
        });
      } else {
        await PromptService.createPrompt({
          body: {
            ...prompt,
            model_type: prompt.model_type,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Prompt saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChangePrompt = (name, value) => {
    setPrompt((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangePromptViaLanguage = (language: string, value: string) => {
    setPrompt((state) => ({
      ...state,
      prompts: {
        ...state.prompts,
        [language]: value,
      },
    }));
  };

  const _models = useMemo(() => {
    return models
      .filter(
        (model) => model.type === prompt.model_type && model.status === "enable"
      )
      .map((model) => ({
        label: model.id,
        value: model.id,
      }));
  }, [prompt.model_type]);

  const invalidTones = useMemo(() => {
    let numberEmpty = 0;
    if (!targetProject?.tones) {
      return true;
    }
    if (targetProject?.tones && targetProject?.languages) {
      for (let i = 0; i < targetProject.languages.length; i++) {
        const targetLanguage = targetProject.languages[i];
        if (targetProject.tones[targetLanguage.key].length === 0) {
          numberEmpty += 1;
        } else {
          break;
        }
      }
    }
    return numberEmpty === targetProject?.languages?.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetProject?.tones]);

  return (
    <div className="grid justify-content-between">
      <div className="col-4">
        <div className="field">
          <label htmlFor="projects">Project</label>
          <Dropdown
            filter
            value={prompt.project_id}
            options={projects.map((project) => ({
              label: project.name,
              value: project._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select project"
            onChange={(e) => onChangePrompt("project_id", e.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            value={prompt.name}
            onChange={(e) => onChangePrompt("name", e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="model">Type of model</label>
          <Dropdown
            value={prompt.model_type}
            options={[
              { id: "completion", name: "Completion" },
              { id: "chat", name: "Chat" },
            ].map((model) => ({
              label: model.name,
              value: model.id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select model type"
            onChange={(e) => {
              onChangePrompt("model_type", e.value);
            }}
          />
        </div>
        <div className="field">
          <label htmlFor="model">Model</label>
          <Dropdown
            value={prompt.model}
            options={_models}
            optionLabel="label"
            optionValue="value"
            placeholder="Select model"
            onChange={(e) => onChangePrompt("model", e.value)}
          />
        </div>
        <div className="field">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="n">N</label>
            <span>{prompt.n}</span>
          </div>
          <Slider
            max={4}
            min={1}
            value={prompt.n}
            onChange={(e) => onChangePrompt("n", e.value)}
          />
        </div>
        <div className="field">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="temperature">Temperature</label>
            <span>{prompt.temperature}</span>
          </div>
          <Slider
            max={1}
            step={0.01}
            value={prompt.temperature}
            onChange={(e) => onChangePrompt("temperature", e.value)}
          />
        </div>
        <div className="field mt-5">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="max-length">Max length</label>
            <span>{prompt.max_tokens}</span>
          </div>
          <Slider
            max={4000}
            min={1}
            value={prompt.max_tokens}
            onChange={(e) => onChangePrompt("max_tokens", e.value)}
          />
        </div>
        <div className="field mt-5">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="max-length">Best of</label>
            <span>{prompt.best_of}</span>
          </div>
          <Slider
            max={20}
            min={1}
            value={prompt.best_of}
            onChange={(e) => onChangePrompt("best_of", e.value)}
          />
        </div>
        <div className="field mt-5">
          <label htmlFor="stop-sequences">Stop sequences</label>
          <InputText
            value={prompt.stop}
            onChange={(e) => onChangePrompt("stop", e.target.value)}
          />
        </div>
        <div className="field mt-5">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="top-p">Top P</label>
            <span>{prompt.top_p}</span>
          </div>
          <Slider
            max={1}
            step={0.01}
            value={prompt.top_p}
            onChange={(e) => onChangePrompt("top_p", e.value)}
          />
        </div>
        <div className="field mt-5">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="presence-penalty">Presence Penalty</label>
            <span>{prompt.presence_penalty}</span>
          </div>
          <Slider
            max={2}
            step={0.01}
            value={prompt.presence_penalty}
            onChange={(e) => onChangePrompt("presence_penalty", e.value)}
          />
        </div>
        <div className="field mt-5">
          <div className="mb-2 flex justify-content-between">
            <label htmlFor="frequency-penalty">Frequency Penalty</label>
            <span>{prompt.frequency_penalty}</span>
          </div>
          <Slider
            max={2}
            step={0.01}
            value={prompt.frequency_penalty}
            onChange={(e) => onChangePrompt("frequency_penalty", e.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="status">Status</label>
          <Dropdown
            value={prompt.status}
            options={Object.keys(COMMON_STATUS).map((status) => ({
              label: status,
              value: COMMON_STATUS[status],
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select status"
            onChange={(e) => onChangePrompt("status", e.value)}
          />
        </div>
      </div>
      <div
        className="col-8 bg-white"
        style={{
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
          borderRadius: "10px",
        }}
      >
        <h6 className="text-center">{targetProject?.description}</h6>
        <ul className="p-0 flex justify-content-evenly list-none">
          {targetProject?.variables.map((variable: any) => (
            <li key={variable.name}>{`{{${variable.name}}}`}</li>
          ))}
          {!invalidTones && targetProject && <li key="tone">{`{{tone}}`}</li>}
        </ul>
        <div
          className="bg-white m-4"
          style={{
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
            borderRadius: "10px",
          }}
        >
          <div className="p-7">
            {targetProject && targetProject?.languages.length === 0 ? (
              <div className="field">
                <label className="text-lg font-bold">Default</label>
                <InputTextarea
                  autoResize
                  value={prompt?.prompts ? prompt.prompts["default"] : ""}
                  onChange={(e) =>
                    onChangePromptViaLanguage("default", e.target.value)
                  }
                />
              </div>
            ) : (
              targetProject?.languages.map((language) => {
                return (
                  <div key={language.key} className="field">
                    <label className="text-lg font-bold" htmlFor={language.key}>
                      {language.name}
                    </label>
                    <InputTextarea
                      autoResize
                      value={
                        prompt?.prompts ? prompt.prompts[language.key] : ""
                      }
                      onChange={(e) =>
                        onChangePromptViaLanguage(language.key, e.target.value)
                      }
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
